import {SimplePageLayout} from '@flexinet/ui-components';

import {IndexPageHeader} from '../components/IndexPageHeader';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {SubscriptionStatusBanner} from "../../../components/SubscriptionStatusBanner.tsx";
import {ServicePlansComponent} from "../components/ServicePlansComponent.tsx";
import {plans} from "../services/service-plans.tsx";

export const IndexPage = () => {

    return (
        <SimplePageLayout
            pageHeader={<IndexPageHeader/>}
            pageNavigation={<IndexPageNavigation/>}
        >

            <ServicePlansComponent plans={plans}/>
            <>
                <SubscriptionStatusBanner/>
                {/*<RecordsTable/>*/}
                <ServicePlansComponent plans={plans}/>
            </>

        </SimplePageLayout>
    );
};
