import {stage} from "../stage.ts";

type ConfigShape = {
    [key: string]: {
        S3: {
            region: string,
            bucket: string,
            // prefix: string,
            // customPrefix: {
            //     public: string,
            //     protected: string,
            //     private: string
            // }
        }
    }
}


const config: ConfigShape = {
    prd: {
        S3: {
            region: 'us-west-2',
            bucket: '',
        }
    },
    test: {
        S3: {
            region: 'us-west-2',
            bucket: '',
        }
    },
    dev: {
        S3: {
            region: 'us-west-2',
            bucket: 'flexi-domains-app-infra-dev-uploadsbucketce7fb8a1-vimb4pe3qrg9',
        }
    },
    ide: {
        S3: {
            region: 'us-west-2',
            bucket: 'flexi-domains-app-infra-dev-uploadsbucketce7fb8a1-vimb4pe3qrg9',
        }
    },
};

export const storageConfig = config[stage]