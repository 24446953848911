export const listDomainNames = `
   query listDomainNames {
       listDomainNames {
           items {
              id
              name
              tldId
              topLevelDomain {
                 id
                 name
              }
              category
              purchaseDate
              expirationDate
              registrarId
              registrar {
                 id
                 name
              }
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getDomainName = `
   query getDomainName($id:ID!) {
       getDomainName(id:$id) {
          id
          name
          tldId
          topLevelDomain {
             id
             name
          }
          category
          purchaseDate
          expirationDate
          registrarId
          registrar {
             id
             name
          }
          createdByUsername
          created
          updated
      } 
}`;
