import {NavLink} from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
} from '@mantine/core';
import {ServicePlan} from '../types';

export const EditPageNavigation = ({component}: { component: ServicePlan }) => {

    const breadcrumbs = [{
        title: 'Home',
        href: '/dashboard',
    },
        {
            title: 'ServicePlans',
            href: `/service-plans`,
        },
        {title: component.name}].map((item, index) => (
        <Box key={index}> {
            item
                .href ?
                <NavLink to={item.href}>{item.title}</NavLink> : item.title
        }
        </Box>
    ));

    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );
};
