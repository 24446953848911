import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';

import {
    FormikSelect,
    FormikTextInput
} from '@flexinet/ui-components';


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='name'
                label='Name'
                description='Please enter the top level domain name with dot in front'
                component={FormikTextInput}
                withAsterisk
            />

            <Field
                name='tldType'
                label='Type'
                description='Please enter top level domain type'
                data={[{
                    label: 'Generic',
                    value: 'generic'
                }, {
                    label: 'Country Code',
                    value: 'country-code'
                }, {
                    label: 'Generic Restricted',
                    value: 'generic-restricted'
                }, {
                    label: 'Sponsored',
                    value: 'sponsored'
                }]}
                component={FormikSelect}
            />


            <Field
                name='tldManager'
                label='Manager'
                description='Please enter the tld manager'
                component={FormikTextInput}
            />

        </SimpleGrid>
    );
};
