import {SimplePageLayout} from '@flexinet/ui-components';


export const TenantMembersPage = () => {

    return (
        <SimplePageLayout
            pageHeader={<div/>}
            pageNavigation={<div/>}
        >
            Tenant Members
        </SimplePageLayout>
    );
};
