import {RecordForm} from '../forms/RecordForm';


import {useServicePlan} from '../hooks/useServicePlan';
import {IndexPageNavigation} from "../navigation/IndexPageNavigation";
import {FormPageLayout} from "@flexinet/ui-components";


export const AddPage = () => {

    const {
        record,
        createRecord,
    } = useServicePlan();

    return (
        <FormPageLayout
            title='Add ServicePlan'
            pageNavigation={<IndexPageNavigation/>}
        >
            <RecordForm record={record} handleSubmit={createRecord}/>
        </FormPageLayout>
    );
};
