import {Stack,} from '@mantine/core';
import {TopLevelDomain,} from '../types';
import {PlatformPropertyCard} from '@flexinet/ui-components';


export const Profile = ({record}: { record: TopLevelDomain }) => {
    console.debug(record)

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Type',
            value: record.tldType as string,
            span: 12,
        }, {
            label: 'Manager',
            value: record.tldManager ? record.tldManager : 'n/a',
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateTopLevelDomain',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <Stack gap={'lg'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};
