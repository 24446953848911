import {DomainName,} from '../types';
import {NavLink} from "react-router-dom";
import {PlatformPropertyCard} from "@flexinet/ui-components";
import {Stack} from "@mantine/core";


export const Profile = ({record}: { record: DomainName }) => {
    console.debug(record)

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Registrar',
            value: <NavLink to={`/registrars/${record.registrarId}`}>{record.registrar?.name}</NavLink>,
            span: 12,
        }, {
            label: 'Category',
            value: record.category as string,
            span: 12,
        }, {
            label: 'Tld',
            value: <NavLink to={`/top-level-domains/${record.tldId}`}>{record.topLevelDomain?.name}</NavLink>,
            span: 12,
        },
        {
            label: 'Purchased',
            value: record.purchaseDate as string,
            formatAs: 'Date',
            span: 12,
        },
        {
            label: 'Expiring',
            value: record.expirationDate as string,
            formatAs: 'Date',
            span: 12,
        }];

    const properties2 = [
        {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? record.createdBy.username : null,
            rbac: 'UpdateDomainName',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <Stack gap={'xl'}>
            <PlatformPropertyCard properties={properties}/>
            <PlatformPropertyCard properties={properties2}/>
        </Stack>
    );


};