import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {AddPage} from './pages/AddPage';
import {EditPage} from './pages/EditPage';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";

export const ServicePlansLayout = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes('ListServicePlans') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            />
            <Route
                path='add'
                element={
                    allowedActions.includes('CreateServicePlan') ? (
                        <AddPage/>
                    ) : (
                        <Navigate to='/service-plans'/>
                    )
                }
            />
            <Route
                path=':id'
                element={
                    allowedActions.includes('GetServicePlan') ? (
                        <EditPage/>
                    ) : (
                        <Navigate to='/service-plans'/>
                    )
                }
            />
        </Routes>
    );
};
