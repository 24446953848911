import {useNavigate} from 'react-router-dom';

import {Image} from '@mantine/core';
import config from "../../config/index";


export const MediumLogo = ({
                               w = '600',
                               h = '200'
                           }: {
    w?: string,
    h?: string
}) => {
    const navigate = useNavigate();

    return (
        <Image
            src={'600x200_logo.png'}
            w={w}
            h={h}
            alt={config.appTitle}
            onClick={() => navigate('/')}
        />
    );
};
