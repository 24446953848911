import {
    useEffect,
    useState,
} from 'react';

import {Autocomplete,} from '@mantine/core';
import {FieldProps} from 'formik';
import {TopLevelDomain} from "../../top-level-domains/types.ts";
import {useRegistrars} from "../hooks/useRegistrars.ts";


export const RegistrarsAutocomplete = ({
                                           field: {
                                               name,
                                               value: fieldValue,
                                           },
                                           form: {setFieldValue},
                                           ...rest
                                       }: FieldProps) => {

    const LABEL_PROPERTY = 'name'
    const VALUE_PROPERTY = 'id'

    const [value, setValue] = useState('');
    const [disabled, setDisabled] = useState(false);

    const {
        items: records,
        fetchRecords
    } = useRegistrars();

    useEffect(() => {
        fetchRecords();
    }, []);


    useEffect(() => {
        if (records.length === 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [records.length]);

    useEffect(() => {
        const found = records.find((record: TopLevelDomain) => record[VALUE_PROPERTY] === fieldValue);
        if (typeof found !== 'undefined') {
            setValue(found[LABEL_PROPERTY]);
        }
    }, [records.length]);

    const handleChange = (value: string) => {
        const found = records.find((record: TopLevelDomain) => record[LABEL_PROPERTY] === value);
        if (typeof found !== 'undefined') {
            setFieldValue(name, found[VALUE_PROPERTY]);
        }
        setValue(value);
    };

    const data = records.map((record: TopLevelDomain) => {
        return {
            label: record[LABEL_PROPERTY],
            value: record[VALUE_PROPERTY],
        };
    });


    return (
        <Autocomplete
            name={name}
            value={value}
            onChange={handleChange}
            data={data}
            disabled={disabled}
            placeholder='Select Component'
            {...rest}
        />
    );
};
