import {PlatformNavbar} from "@flexinet/ui-components";
import {getMenuItems} from "../data/menus.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalNavbar = () => {

    const {allowedActions} = useVerifiedPermissions()


    const items = getMenuItems()

    const menuItems = items.filter((item) => {
        return allowedActions.includes(item.rbac as string)
    });

    return <PlatformNavbar
        navbarHeader={'Flexi Domains'}
        menuItems={menuItems}/>

};