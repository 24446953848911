import {Amplify} from 'aws-amplify';

import {stage} from '../config/stage';

// get config
import {ConsoleLogger} from 'aws-amplify/utils';
import {config} from "../config/amplify";

Amplify.configure(config)

stage === 'ide'
    ? (ConsoleLogger.LOG_LEVEL = 'DEBUG')
    : (ConsoleLogger.LOG_LEVEL = 'INFO');

declare let window: any;

window.LOG_LEVEL = 'DEBUG';

const currentConfig = Amplify.getConfig();
console.debug('currentConfig', currentConfig);

