import {NavLink} from 'react-router-dom';
import {useEffect} from "react";
import {useRegistrars} from "../../../registrars";
import {
    DashboardWidgetBuilder,
    WidgetType
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";

export const RegistrarsWidget = () => {

    const {
        items,
        fetchRecords
    } = useRegistrars()

    useEffect(() => {
        fetchRecords()
    }, []);

    const {allowedActions} = useVerifiedPermissions()

    const actionItems = [
        {
            label: 'View All',
            description:
                'View All Registrars',
            component: {NavLink},
            to: '/registrars',
            visible: true
        },
    ];

    if (allowedActions.includes('CreateRegistrar')) {
        actionItems.push({
                             label: 'Onboard Registrar',
                             description:
                                 'Onboard new registrar',
                             component: {NavLink},
                             to: '/registrars/add',
                             visible: true,
                         })
    }


    const widget: WidgetType = {
        header: {
            title: 'Registrars',
        },
        body: {
            dataPoints: [{
                value: items.length,
                label: 'Registrars',
                color: 'flexinet',
            }],
            metrics: [],
        },
        footer: {
            actionItems: actionItems
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
