export const listServicePlans = `
   query listServicePlans {
       listServicePlans {
           items {
             id
             name
             description
             website
             createdByUsername
             created
             updated
           }
       }
   }
`

export const getServicePlan = `
   query getServicePlan($id:ID!) {
          getServicePlan(id:$id) {
            id
            name
            description
            website
            createdByUsername
            created
            updated
         }
}`