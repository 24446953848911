import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/RegistrarsService';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Registrar} from '../types';

const entityKey = 'registrars';

interface RegistrarsState {
    byId: { [key: string]: Registrar },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as RegistrarsState;


const normalizeRecord = (record: Registrar) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchRegistrars = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: Registrar) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchRegistrar = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveRegistrar = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Registrar) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateRegistrar = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Registrar }) => {

        const updated = Object.assign({}, {
            id: record.id,
            name: record.name,
            description: record.description,
            website: record.website,
        })

        return update(id, updated)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteRegistrar = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeRegistrar = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Registrar) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchRegistrars, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchRegistrar);

                                            // SAVE ONE
                                            saveOneCase(builder, saveRegistrar);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateRegistrar);

                                            updateOneCase(builder, storeRegistrar);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteRegistrar);
                                        },
                                    });

export default componentsSlice.reducer;
