import {HomePageMarketing} from "../components/HomePageMarketing.tsx";
import {Navigate} from "react-router-dom";
import {useUserAttributes} from "../../../hooks/useUserAttributes.ts";

export const PublicHomePage = () => {
    const {email} = useUserAttributes();
    if (email) {
        return <Navigate to={'/dashboard'}/>
    }
    return <HomePageMarketing/>
};
