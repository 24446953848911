import {NavLink} from 'react-router-dom';
import {useEffect} from "react";
import {
    DashboardWidgetBuilder,
    WidgetType
} from "@flexinet/ui-components";
import {useTopLevelDomains} from "../../../top-level-domains";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";

export const TopLevelDomainsWidget = () => {

    const {
        items,
        fetchRecords
    } = useTopLevelDomains()

    useEffect(() => {
        fetchRecords()
    }, []);

    const {allowedActions} = useVerifiedPermissions()

    const actionItems = [
        {
            label: 'View All',
            description:
                'View all top level domains',
            component: {NavLink},
            to: '/top-level-domains',
            visible: true
        },
    ];

    if (allowedActions.includes('AddTopLevelDomain')) {
        actionItems.push({
                             label: 'Add Top Level Domain',
                             description:
                                 'Add new top level domain',
                             component: {NavLink},
                             to: '/top-level-domains/add',
                             visible: true,
                         })
    }


    const widget: WidgetType = {
        header: {
            title: 'Top Level Domains',
        },
        body: {
            dataPoints: [{
                value: items.length,
                label: 'Top Level Domains',
                color: 'flexinet',
            }],
            metrics: [],
        },
        footer: {
            actionItems: actionItems
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
