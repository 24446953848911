import {
    Center,
    Container,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {ServicePlansComponent} from "../../service-plans/components/ServicePlansComponent.tsx";
import {plans} from "../../service-plans/services/service-plans.tsx";


const PricingComponent = () => {
    return (
        <Center>
            <Stack>
                <Center>
                    <Title><Text style={{
                        marginTop: 'var(--mantine-spacing-xl)',
                        color: 'var(--mantine-color-dark-3)'
                    }}>Flexi Domains Service
                        Plans</Text></Title>
                </Center>
                <ServicePlansComponent plans={plans}/>
                <Container>
                    <Text style={{
                        fontSize: 'small',
                        fontWeight: '800',
                        textAlign: 'center',
                    }}>
                        Pricing is based on the number of domain names that you need to manage. Please note that these
                        prices are
                        for illustrative purposes only and subject to change. Actual prices may vary.
                    </Text>
                </Container>
            </Stack>
        </Center>
    )
}

export const PricingPage = () => {
    return <PricingComponent/>
};
