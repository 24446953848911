import {SimplePageLayout} from '@flexinet/ui-components';


export const PaymentAndBillingPage = () => {

    return (
        <SimplePageLayout
            pageHeader={<div/>}
            pageNavigation={<div/>}
        >
            Payment and Billing
        </SimplePageLayout>
    );
};
