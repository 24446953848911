import {useNavigate} from 'react-router-dom';
import {useToggle} from '@mantine/hooks';


import {AiOutlineProfile} from 'react-icons/ai';
import {
    FlexiButton,
    FlexiCloseButton,
    FlexiEditButton
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();


    const {allowedActions} = useVerifiedPermissions()

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (allowedActions.includes('UpdateTopLevelDomain')) {

        if (mode === 'edit') {
            tools.push(<FlexiButton key={'profile'}
                                    leftSection={<AiOutlineProfile/>}
                                    onClick={() => toggle()}>Profile</FlexiButton>);
        } else {
            tools.push(<FlexiEditButton variant={'filled'} key={'edit'}
                                        onClick={() => toggle()}>Edit</FlexiEditButton>);
        }

    }

    tools.push(<FlexiCloseButton key={'close'}
                                 onClick={() => navigate(`/top-level-domains`)}>Close</FlexiCloseButton>);


    return {
        mode,
        tools,
    };

};
