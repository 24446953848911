export const createTopLevelDomain = `
    mutation createTopLevelDomain($input: CreateTopLevelDomainInput!) {
       createTopLevelDomain(input:$input) {
             id
             name
             tldType
             tldManager
       }
}`

export const updateTopLevelDomain = `
    mutation updateTopLevelDomain($input: UpdateTopLevelDomainInput!) {
        updateTopLevelDomain(input:$input){
            id
            name
            tldType
            tldManager
        }
}`

export const deleteTopLevelDomain = `
    mutation deleteTopLevelDomain($input: DeleteTopLevelDomainInput!) {
        deleteTopLevelDomain(input:$input) {
            id
        }
}`