import {NavLink} from 'react-router-dom';
import {useUserAttributes} from "../../../../hooks/useUserAttributes.ts";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";

export const ProfileWidget = () => {
    const {
        givenName,
        familyName,
        email,
    } = useUserAttributes()


    const widget: WidgetType = {
        header: {
            title: `Good Morning, ${givenName} ${familyName}`,
        },
        body: {
            dataPoints: [
                {
                    value: null,
                    label: `Name: ${givenName} ${familyName}`,
                    color: 'black',
                },
                {
                    value: null,
                    label: `Email: ${email}`,
                    color: 'black',
                },
            ] as WidgetDataPoint[],
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'Upgrade Service Plan',
                    description: 'Select service plan',
                    component: {NavLink},
                    to: '/service-plans',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
