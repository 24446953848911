export const actions = [
    'GetDashboard',
    'ViewGettingStarted',

    'ListDomainNames',
    'GetDomainName',
    'CreateDomainName',
    'UpdateDomainName',
    'DeleteDomainName',

    'ListRegistrars',
    'GetRegistrar',
    'CreateRegistrar',
    'UpdateRegistrar',
    'DeleteRegistrar',

    'ListTopLevelDomains',
    'GetTopLevelDomain',
    'CreateTopLevelDomain',
    'UpdateTopLevelDomain',
    'DeleteTopLevelDomain',

    'ListServicePlans',
    'GetServicePlan',
    'CreateServicePlan',
    'UpdateServicePlan',
    'DeleteServicePlan',

    'ListSettings',
    'GetSetting',
    'UpdateSetting',

];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);