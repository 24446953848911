import {
    Box,
    Center,
    Container,
    Grid,
    Paper,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {CallToActionComponent} from "./CallToActionComponent.tsx";
import {
    AiOutlineCloudServer,
    AiOutlineDollar,
    AiOutlineOrderedList,
    AiOutlineWallet
} from "react-icons/ai";
import {ReactNode} from "react";


// product tile component
export const ProductTile = ({
                                product
                            }: {
    product: { title: string, description: string, icon: ReactNode, position: string }
}) => {

    switch (product.position) {
        case 'left':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '2em'
                        }}>
                            {product.icon}
                        </Grid.Col>

                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-7)'}}>{product.title}</Title>
                            </Box>
                            <Box>
                                <Text>{product.description}</Text>
                            </Box>
                        </Grid.Col>

                    </Grid>
                </Paper>
            )
        case 'right':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid>
                        <Grid.Col span={8}>
                            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                                <Title order={3} style={{color: 'var(--mantine-color-dark-6)'}}>{product.title}</Title>
                            </Box>
                            <Box>
                                <Text>{product.description}</Text>
                            </Box>
                        </Grid.Col>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '4em'
                        }}>
                            {product.icon}
                        </Grid.Col>
                    </Grid>
                </Paper>
            )
    }

}

export const ProductsComponent = () => {

    // array of service plans
    const products = [
        {
            title: "Centralized Domain Name Management",
            description: "Streamline your domain name management with our comprehensive platform, designed to simplify and centralize your domain portfolio. Our intuitive interface allows you to effortlessly create, update, and delete domain name records, ensuring your online presence remains accurate and up-to-date.",
            icon: <AiOutlineCloudServer size={'9em'} color={'var(--mantine-color-flexinet-0)'}/>,
            position: 'left'
        },
        {
            title: "Registrar Management and Expense Tracking",
            description: "Efficiently manage your registrars and associated domain names within our centralized platform. Easily create, update, and delete registrar information, and seamlessly link registrars to their corresponding domain names. Our comprehensive expense tracking feature provides a clear overview of all domain name purchase and renewal costs, empowering you to make informed financial decisions.",
            icon: <AiOutlineDollar size={'9em'} color={'var(--mantine-color-green-8)'}/>,
            position: 'right'
        },
        {
            title: "Comprehensive Domain Name Portfolio Management",
            description: "Our robust domain name management solution empowers you to effortlessly manage your entire domain portfolio, regardless of its size or complexity. With features like centralized domain name record management, registrar management, and expense tracking, you can maintain complete control over your online presence.",
            icon: <AiOutlineOrderedList size={'9em'} color={'var(--mantine-color-flexinet-0)'}/>,
            position: 'left'
        },
        {
            title: "Simplified Enterprise Domain Name Management",
            description: "Our intuitive domain name management platform is specifically tailored to the needs of enterprises. Our user-friendly interface and comprehensive features empower you to efficiently manage your domain portfolio, ensuring your online presence remains consistent and secure.",
            icon: <AiOutlineWallet size={'9em'} color={'var(--mantine-color-red-8)'}/>,
            position: 'right'
        },

    ];

    return (
        <Container>
            <Stack>
                <Center>
                    <Title><Text style={{
                        marginTop: 'var(--mantine-spacing-xl)',
                        color: 'var(--mantine-color-dark-3)'
                    }}>Flexi Domains Products</Text></Title>
                </Center>
                <Stack style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    {products.map(product => {
                        return <ProductTile product={product} key={product.title}/>
                    })}
                </Stack>
                <Center style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                    <CallToActionComponent/>
                </Center>
            </Stack>
        </Container>
    );
};
