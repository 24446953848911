import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {useDomainNames} from '../hooks/useDomainNames';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';
import {useDomainNamesSelection} from '../hooks/useDomainNamesSelection';
import {IDomainNameTableColumn,} from '../types';
import {useParams} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {allowedActions} = useVerifiedPermissions()

    const {id: registrarId} = useParams();

    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;


    const {
        columns,
        visibleItems,
        limit,
        loading,
        totalPages,
        visibleCount,
        fetchRecords,
        handleFilterByKeyword,
        handleItemsPerPageChange,
        handlePageChange,
    } = useDomainNames(registrarId);


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useDomainNamesSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: IDomainNameTableColumn[] = [];
        if (allowedActions.includes('DeleteDomainName')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (item.createdByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}/></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);

        if (allowedActions.includes('DeleteDomainName')) {
            cols.push({
                          id: 'createdByUsername',
                          title: 'Created By',
                          render: item => item.createdBy?.username
                      },
            );
        }

        return cols;


    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};
