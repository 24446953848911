import {
    Card,
    Center,
    Container,
    Grid,
    Group,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {
    AiOutlineArrowRight,
    AiOutlineCreditCard,
    AiOutlineMail
} from "react-icons/ai";
import {NavLink} from "react-router-dom";
import {RecordIcon as RegistrarIcon} from "../../registrars/images/RecordIcon";
import {RecordIcon as DomainNameIcon} from "../../domain-names/images/RecordIcon";
import {RecordIcon as TopLevelDomainIcon} from "../../top-level-domains/images/RecordIcon";
import {FaAws} from "react-icons/fa";


const sections = [{
    title: 'Add Registrars',
    text: 'Add unlimited number of registrars to your portfolio',
    icon: <RegistrarIcon size={'3em'}/>,
    link: {
        title: 'Registrars',
        path: '/registrars/add'
    },
    visible: true
}, {
    title: 'View Top Level Domains',
    text: 'Browse top level domains for your portfolio',
    icon: <TopLevelDomainIcon size={'3em'}/>,
    link: {
        title: 'Top Level Domains',
        path: '/top-level-domains'
    },
    visible: true
}, {
    title: 'Add Domain Names',
    text: 'Add domain name(s) to your portfolio.',
    icon: <DomainNameIcon size={'3em'}/>,
    link: {
        title: 'Domain Names',
        path: '/domain-names/add'
    },
    visible: true
}, {
    title: 'Enable Integration with AWS',
    text: 'Enable integration with AWS',
    icon: <FaAws size={'3em'}/>,
    link: {
        title: 'AWS',
        path: '/route53-domains'
    },
    visible: false
}, {
    title: 'Invite others to use the application',
    text: 'Send invitations to other users to use the application.',
    icon: <AiOutlineMail size={'3em'}/>,
    link: {
        title: 'Invitations',
        path: '/settings/tenant-members'
    },
    visible: false
}, {
    title: 'Update Your Billing Info',
    text: 'Update your billing info. ',
    icon: <AiOutlineCreditCard size={'3em'}/>,
    link: {
        title: 'Billing',
        path: '/settings/payment-and-billing'
    },
    visible: false
}]

export const IndexPage = () => {

    const visibleSections = sections.filter((section) => section.visible);

    return (
        <Container>
            <Center p={'xl'}>
                <Title>Getting Started</Title>
            </Center>

            {visibleSections.map((section, index) =>
                                     <Card p={'xl'} m={'xl'} shadow={'sm'} key={index}>
                                         <Grid>
                                             <Grid.Col span={2}>
                                                 {section.icon}
                                             </Grid.Col>

                                             <Grid.Col span={7}>
                                                 <Stack>
                                                     <Group>
                                                         <Title order={2}>{section.title}</Title>
                                                     </Group>
                                                     <Text
                                                         style={{color: 'var(--mantine-color-gray-6)'}}>{section.text}</Text>
                                                 </Stack>
                                             </Grid.Col>

                                             <Grid.Col span={3}>
                                                 <Group justify={'flex-end'}>
                                                     <NavLink to={section.link.path}>
                                                         <Group>
                                                             <Text> {section.link.title}</Text>
                                                             <AiOutlineArrowRight/>
                                                         </Group>
                                                     </NavLink>
                                                 </Group>
                                             </Grid.Col>
                                         </Grid>
                                     </Card>
            )}
        </Container>
    )

};
