import React from 'react';

import {
    Box,
    Center,
    Container,
    Group,
    Paper,
    Text,
    Title,
} from '@mantine/core';

import classes from "./HomePageMarketing.module.css";
import {MediumLogo} from "../../../components/logo/MediumLogo.tsx";
import {CallToActionComponent} from "./CallToActionComponent.tsx";
import {AiOutlineArrowRight} from "react-icons/ai";
import {FlexiButton} from "@flexinet/ui-components";
import {useNavigate} from "react-router-dom";

export const HomePageMarketing = () => {

    const navigate = useNavigate()

    return (
        <React.Fragment>
            <Container className={classes.wrapper} size={1400}>

                <Center className={classes.logo}>
                    <MediumLogo/>
                </Center>

                <Box className={classes.inner}>


                    <Title className={classes.title}>
                        Manage your{' '}
                        <Text
                            component="span"
                            c={'blue'}
                        >
                            domain names portfolio
                        </Text>{' '}
                        across registrars
                    </Title>

                    <Container p={0} size={600}>
                        <Text className={classes.description}>
                            Track registrations, renewals, cost, contacts and reminders
                        </Text>
                    </Container>

                    <Container p={100} size={700}>
                        <Paper shadow="xl" p="xl" withBorder>
                            <Center>
                                <Title>As low as</Title>
                            </Center>
                            <Center>
                                <Title>50 &#162;</Title>
                            </Center>
                            <Center>
                                <Title>domain name / month</Title>
                            </Center>

                            <Center mt={'xl'}>
                                <FlexiButton variant={'outline'} onClick={() => navigate('/pricing')}>
                                    <Group>
                                        pricing details
                                        <AiOutlineArrowRight color={'var(--mantine-color-blue)'}/>
                                    </Group>
                                </FlexiButton>
                            </Center>
                        </Paper>
                    </Container>

                    <Center>
                        <CallToActionComponent/>
                    </Center>

                </Box>
            </Container>
        </React.Fragment>
    );
};
