import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/DomainNamesService';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {DomainName} from '../types';
import {findRecordByNameSelector} from "../../top-level-domains";

const entityKey = 'domainNames';

interface DomainNamesState {
    byId: { [key: string]: DomainName },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as DomainNamesState;


const normalizeRecord = (record: DomainName) => {
    return Object.assign({}, record) as DomainName;
};

// generates pending, fulfilled and rejected
export const fetchDomainNames = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {

        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: DomainName) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchDomainName = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveDomainName = createAsyncThunk(
    `${entityKey}/add`,
    (payload: DomainName, {getState}) => {

        const domainName = payload.name?.split('.')[0];
        const tldName = payload.name?.split('.')[1];
        console.debug(domainName, tldName)

        const state = getState();
        const {record: tld} = findRecordByNameSelector(state, tldName as string)

        console.debug(tld)

        payload.tldId = tld.id

        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateDomainName = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: DomainName }) => {

        const modified = {
            id: record.id,
            name: record.name,
            category: record.category,
            tldId: record.tldId,
            registrarId: record.registrarId,
            purchaseDate: record.purchaseDate,
            expirationDate: record.expirationDate
        }

        return update(id, modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(record as DomainName);
                }
            });
    },
);

export const deleteDomainName = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeDomainName = createAsyncThunk(
    `${entityKey}/store`,
    (payload: DomainName) => {
        return normalizeRecord(payload as DomainName);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchDomainNames, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchDomainName);

                                            // SAVE ONE
                                            saveOneCase(builder, saveDomainName);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateDomainName);

                                            updateOneCase(builder, storeDomainName);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteDomainName);
                                        },
                                    });

export default componentsSlice.reducer;
