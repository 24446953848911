export const listTopLevelDomains = `
   query listTopLevelDomains {
       listTopLevelDomains {
            items {
                 id
                 name
                 tldType
                 tldManager
                 created
                 updated
            }
       }
   }
`

export const getTopLevelDomain = `
   query getTopLevelDomain($id:ID!) {
         getTopLevelDomain(id:$id) {
            id
            name
            tldType
            tldManager
            created
            updated
         }
}`