import {
    configureStore,
    Store
} from '@reduxjs/toolkit';


import {DomainNamesReducer} from '../features/domain-names/reducers/index'
import {TopLevelDomainsReducer} from '../features/top-level-domains/reducers/index'
import {RegistrarsReducer} from '../features/registrars/reducers/index'
import {ServicePlansReducer} from "../features/service-plans/reducers";

const reducers = {
    domainNames: DomainNamesReducer,
    topLevelDomains: TopLevelDomainsReducer,
    registrars: RegistrarsReducer,
    servicePlans: ServicePlansReducer,
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch