import {Avatar} from '@mantine/core';

import {AiOutlineUnorderedList} from 'react-icons/ai';

export const RecordIcon = ({
                               size = 'md',
                           }: {
    size?: string
}) => {
    return (
        <Avatar size={size}>
            <AiOutlineUnorderedList/>
        </Avatar>
    );
};




