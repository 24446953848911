import {
    deleteDomainName,
    fetchDomainName,
    saveDomainName,
    updateDomainName,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {DomainName} from '../types';

export const useDomainName = (domainNameId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, domainNameId ? domainNameId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof domainNameId === 'undefined') {
            throw new Error('domainNameId is required parameter');
        }
        dispatch(fetchDomainName(domainNameId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: DomainName) => {
        dispatch(saveDomainName(values));
    };

    const updateRecord = (values: DomainName) => {
        if (typeof domainNameId === 'undefined') {
            throw new Error('domainNameId is required parameter');
        }

        dispatch(updateDomainName({
                                      id: domainNameId,
                                      record: values,
                                  }));
    };

    const deleteRecord = () => {
        if (typeof domainNameId === 'undefined') {
            throw new Error('domainNameId is required parameter');
        }
        dispatch(deleteDomainName(domainNameId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
