import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/TopLevelDomainsService';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {TopLevelDomain} from '../types';

const entityKey = 'topLevelDomains';

interface TopLevelDomainsState {
    byId: { [key: string]: TopLevelDomain },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as TopLevelDomainsState;


const normalizeRecord = (record: TopLevelDomain) => {
    return Object.assign({}, record, {id: record.id});
};

// generates pending, fulfilled and rejected
export const fetchTopLevelDomains = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    () => {
        return fetchRecords({})
            .then((response) => {
                if (response) {
                    return response.map((record: TopLevelDomain) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchTopLevelDomain = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveTopLevelDomain = createAsyncThunk(
    `${entityKey}/add`,
    (payload: TopLevelDomain) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateTopLevelDomain = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: TopLevelDomain }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteTopLevelDomain = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeTopLevelDomain = createAsyncThunk(
    `${entityKey}/store`,
    (payload: TopLevelDomain) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchTopLevelDomains, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchTopLevelDomain);

                                            // SAVE ONE
                                            saveOneCase(builder, saveTopLevelDomain);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateTopLevelDomain);

                                            updateOneCase(builder, storeTopLevelDomain);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteTopLevelDomain);
                                        },
                                    });

export default componentsSlice.reducer;
