import {Field} from 'formik';

import {
    Container,
    Fieldset,
    Text,
    Title
} from '@mantine/core';
import {FileUploadInput} from "../inputs/FileUploadInput.tsx";


export const UploadFormComponent = () => {

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Upload</Text></Title>} px={'xl'}
                      mb={'xl'}>

                <Field
                    name='fileName'
                    label='File'
                    description='Please select the file'
                    component={FileUploadInput}
                    withAsterisk
                    my={'xl'}
                />

            </Fieldset>


        </Container>
    );
};