import {SimplePageLayout} from '@flexinet/ui-components';


export const SubscriptionsPage = () => {

    return (
        <SimplePageLayout
            pageHeader={<div/>}
            pageNavigation={<div/>}
        >
            Subscriptions
        </SimplePageLayout>
    );
};
