import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {DomainName} from '../types';
import {
    ActionIcon,
    Group
} from "@mantine/core";
import {AiOutlineLink} from "react-icons/ai";

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: DomainName) => <Group>
            <NavLink to={`/domain-names/${item.id}`}>{item.name}</NavLink>
            <ActionIcon variant={"subtle"} onClick={() => window.open('http://' + item.name)}>
                <AiOutlineLink/>
            </ActionIcon>
        </Group>,
    },
    {
        title: I18n.get('Registrar'),
        width: '1fr',
        render: (item: DomainName) => <NavLink to={`/registrars/${item.registrarId}`}>{item.registrar?.name}</NavLink>,
    },
    {
        title: I18n.get('Category'),
        width: '1fr',
        render: (item: DomainName) => item.category,
    },
    {
        title: I18n.get('TLD'),
        width: '1fr',
        render: (item: DomainName) => <NavLink
            to={`/top-level-domains/${item.tldId}`}>{item.topLevelDomain?.name}</NavLink>,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: DomainName) => formatTimestamp(item.created as string),
    },
];
