export const createRegistrar = `
    mutation createRegistrar($input: CreateRegistrarInput!) {
       createRegistrar(input:$input) {
            id
            name
            description
            website
            createdByUsername
            created
            updated
       }
}`

export const updateRegistrar = `
    mutation updateRegistrar($input: UpdateRegistrarInput!) {
        updateRegistrar(input:$input){
            id
            name
            description
            website
            createdByUsername
            created
            updated
        }
}`

export const deleteRegistrar = `
    mutation deleteRegistrar($input: DeleteRegistrarInput!) {
        deleteRegistrar(input:$input) {
            id
        }
}`