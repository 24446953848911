import '@aws-amplify/ui-react/styles.css';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@flexinet/ui-components/style.css';


import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";
import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";


import {UserAttributesContextProvider} from "./context/UserAttributesContext.tsx";
import {Notifications} from "@mantine/notifications";


import 'aws-amplify/auth/enable-oauth-listener';
import {
    fetchUserAttributes,
    getCurrentUser
} from 'aws-amplify/auth';
import {Hub} from 'aws-amplify/utils';
import {AuthProvider} from "./context/AuthContext.tsx";
import {VerifiedPermissionsProvider} from './context/VerifiedPermissionsProvider.tsx';
import {actions} from "./config/rbac/actions.ts";
import {routesConfig} from "./config/routes.tsx";


Hub.listen("auth", async ({payload}) => {
    switch (payload.event) {
        case "signInWithRedirect":
            const user = await getCurrentUser();
            const userAttributes = await fetchUserAttributes();
            console.debug({
                              user,
                              userAttributes
                          });
            break;
        case "signInWithRedirect_failure":
            // handle sign in failure
            console.debug('SIGN IN WITH REDIRECT FAILURE: ', payload.data);
            break;
        case "customOAuthState":
            const state = payload.data; // this will be customState provided on signInWithRedirect function
            console.debug('CUSTOM STATE: ', state);
            break;
    }
});


export const App = () => {

    const router = createBrowserRouter(routesConfig)

    return (
        <MantineProvider theme={theme}>
            <AuthProvider>
                <VerifiedPermissionsProvider actions={actions}>
                    <UserAttributesContextProvider>
                        <Notifications position={'bottom-left'}/>
                        <RouterProvider router={router}/>
                    </UserAttributesContextProvider>
                </VerifiedPermissionsProvider>
            </AuthProvider>
        </MantineProvider>
    );

}

