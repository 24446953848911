import {stage} from '../stage';

const appsync = {
    prod: {
        endpoint: 'https://pkq2dfpggfdwdamhpufbgl5oca.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    test: {
        endpoint: 'https://lrphlr2prfhbfgwo2t7kbqxmwm.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    dev: {
        endpoint: 'https://dqkchqk2hzbz3coihkzjvxbbtm.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    ide: {
        endpoint: 'https://dqkchqk2hzbz3coihkzjvxbbtm.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
};

export const appSyncConfig = appsync[stage];
