import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        enabled: boolean;
        policyStoreId: string;
        region: string;
        namespace?: string;
    };
};

const config: ConfigShape = {
    prod: {
        enabled: true,
        policyStoreId: 'KSsKnde3eGmnJkbEtU6Dbd',
        region: 'us-west-2',
        namespace: 'FlexiDomains'
    },
    test: {
        enabled: true,
        policyStoreId: '8r1tqsZDy1PkoXwA71aC2Q',
        region: 'us-west-2',
        namespace: 'FlexiDomains',
    },
    dev: {
        enabled: true,
        policyStoreId: 'UVVFdgcH4jzhn94N3neih2',
        region: 'us-west-2',
        namespace: 'FlexiDomains',
    },
    ide: {
        enabled: true,
        policyStoreId: 'UVVFdgcH4jzhn94N3neih2',
        region: 'us-west-2',
        namespace: 'FlexiDomains',
    },
};

export default config[stage];
