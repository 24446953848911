import {PortalDashboard} from "../components/PortalDashboard.tsx";
import {SubscriptionStatusBanner} from "../../../components/SubscriptionStatusBanner.tsx";
import {Stack} from "@mantine/core";


export const IndexPage = () => {

    return <Stack gap={"xl"}>
        <SubscriptionStatusBanner/>
        <PortalDashboard/>
    </Stack>;
};

