import {I18n} from '@aws-amplify/core';

import {
    DeleteRecordsConfirmationButton,
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box,} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useDomainNames} from '../hooks/useDomainNames';
import {useDomainNamesSelection} from "../hooks/useDomainNamesSelection.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useDomainNames();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Domain Name',
                             onClick,
                         }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('/domain-names/add');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};

const UploadButton = ({
                          buttonText = 'Upload',
                          onClick,
                      }: {
    buttonText?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('/domain-names/upload');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};

const DeleteRecordButton = () => {

    const {deleteRecords} = useDomainNames();

    const {
        selection,
        handleClearSelection,
    } = useDomainNamesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }


    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};


const useTools = () => {

    const {allowedActions} = useVerifiedPermissions()

    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteDomainName')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateDomainName')) {
        tools.push(<UploadButton/>);
    }

    if (allowedActions.includes('CreateDomainName')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Domain Names')}
            tools={useTools()}
        />
    );
};
