import {ReactNode} from 'react';
import {Navigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth.ts";


export const RequireAuth = ({children}: { children: ReactNode }) => {
    const {user} = useAuth();
    if (!user) {
        return <Navigate to="/"/>;
    }
    return children;
};
