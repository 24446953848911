// array of service plans
import {ServicePlan} from "../types.ts";


export const plans = [
    {
        id: "domain-names-investor",
        name: "Domain Names Investor",
        baseFee: "70",
        included: "60",
        price: "1",
        features: [
            {
                title: "Above 100 domains pay only $0.75, $.5 above 400",
                supported: true
            },
            {
                title: "Up to 50 registrars. $1 / alias after that",
                supported: true
            },
            {
                title: "Up to 50 email aliases. $1 / alias after that",
                supported: true
            },
            {
                title: "1 User seat included. $25 / user per month after that",
                supported: true
            },
            {
                title: "SSO Integration",
                supported: false
            },
            {
                title: "Priority Support",
                supported: false
            },
        ],
        freeTrial: true,
        current: false
    },
    {
        id: "enterprise-portfolio-manager",
        name: "Enterprise Portfolio Manager",
        baseFee: "300",
        included: "200",
        price: "1",
        features: [
            {
                title: "Up to 5000 domain names",
                supported: true
            },
            {
                title: "Up to 500 registrars",
                supported: true
            },
            {
                title: "Up to 100 email aliases",
                supported: true
            },
            {
                title: "Up to 20 User seats included",
                supported: true
            },
            {
                title: "SSO Integration available",
                supported: true
            },
            {
                title: "Priority Support",
                supported: true
            },
        ],
        freeTrial: true,
        current: false
    },

] as ServicePlan[];

