import {
    createContext,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import {
    fetchUserAttributes,
    signOut
} from "aws-amplify/auth";
import {useAuth} from "../hooks/useAuth.ts";

type UserAttributesContextType = {
    givenName: string,
    familyName: string,
    name: string
    email: string,
    accountId: string,
    accountAdmin: string,
}

export const UserAttributesContext = createContext<UserAttributesContextType>({
                                                                                  givenName: '',
                                                                                  familyName: '',
                                                                                  name: '',
                                                                                  email: '',
                                                                                  accountId: '',
                                                                                  accountAdmin: '',
                                                                              });


type UserAttributesContextProviderProps = {
    children?: ReactNode;
}

export const UserAttributesContextProvider = (props: UserAttributesContextProviderProps) => {

    const {
        user,
    } = useAuth();


    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [accountId, setAccountId] = useState('')
    const [accountAdmin, setAccountAdmin] = useState('')


    useEffect(() => {

        const fetchAttributes = async () => {
            if (!user) {
                return
            }

            let attributes = {} as { [key: string]: any }
            try {
                attributes = await fetchUserAttributes();
            } catch (e: unknown) {
                if (e instanceof Error) {
                    switch (e.name) {
                        case 'NotAuthorizedException':
                            console.debug('Message', e.message)
                            if (e.message.includes('Token is inactive')) {
                                await signOut()
                                window.location.href = '/';
                            }
                            break
                    }
                }
            }


            if (Array.isArray(Object.keys(attributes)) && Object.keys(attributes).length > 0) {

                Object.keys(attributes)
                      .forEach((key: string) => {

                          switch (key) {
                              case 'given_name':
                                  setGivenName(attributes[key])
                                  break
                              case 'family_name':
                                  setFamilyName(attributes[key])
                                  break
                              case 'name':
                                  setName(attributes[key])
                                  break
                              case 'email':
                                  setEmail(attributes[key])
                                  break
                              case 'custom:account_id':
                                  setAccountId(attributes[key])
                                  break
                              case 'custom:account_admin':
                                  setAccountAdmin(attributes[key])
                                  break
                          }
                      })

            }

        }

        fetchAttributes()

    }, [user])


    return (
        <UserAttributesContext.Provider
            value={{
                givenName,
                familyName,
                name,
                email,
                accountId,
                accountAdmin,
            }}
            {...props} />
    );
};
