import {
    deleteTopLevelDomain,
    fetchTopLevelDomain,
    saveTopLevelDomain,
    updateTopLevelDomain,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {TopLevelDomain} from '../types';

export const useTopLevelDomain = (topLevelDomainId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, topLevelDomainId ? topLevelDomainId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof topLevelDomainId === 'undefined') {
            throw new Error('topLevelDomainId is required parameter');
        }
        dispatch(fetchTopLevelDomain(topLevelDomainId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: TopLevelDomain) => {
        dispatch(saveTopLevelDomain(values));
    };

    const updateRecord = (values: TopLevelDomain) => {
        if (typeof topLevelDomainId === 'undefined') {
            throw new Error('topLevelDomainId is required parameter');
        }

        dispatch(updateTopLevelDomain({
                                          id: topLevelDomainId,
                                          record: values,
                                      }));
    };

    const deleteRecord = () => {
        if (typeof topLevelDomainId === 'undefined') {
            throw new Error('topLevelDomainId is required parameter');
        }
        dispatch(deleteTopLevelDomain(topLevelDomainId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
