export const listRegistrars = `
   query listRegistrars {
       listRegistrars {
           items {
             id
             name
             description
             website
             createdByUsername
             created
             updated
           }
       }
   }
`

export const getRegistrar = `
   query getRegistrar($id:ID!) {
          getRegistrar(id:$id) {
            id
            name
            description
            website
            createdByUsername
            created
            updated
         }
}`