import {Center} from '@mantine/core';
import {PlatformLoader} from '@flexinet/ui-components';
import {useEffect} from "react";
import {signOut} from 'aws-amplify/auth';

export const SignOutPage = () => {

    useEffect(() => {

        const signOutAndRedirect = async () => {
            localStorage.setItem('amplify-signin-with-hostedUI', 'false');
            await signOut()
            window.location.href = '/';
        }

        signOutAndRedirect()
    }, []);


    return (
        <Center>
            <PlatformLoader message={'Signing out...Please, wait!'}/>
        </Center>
    );
};
