import {NavLink} from 'react-router-dom';
import {useEffect} from "react";
import {useDomainNames} from "../../../domain-names";
import {
    DashboardWidgetBuilder,
    WidgetType
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../../hooks/useVerifiedPermissions.ts";

export const DomainNamesWidget = () => {

    const {
        items,
        fetchRecords
    } = useDomainNames()

    useEffect(() => {
        fetchRecords()
    }, []);


    const {allowedActions} = useVerifiedPermissions()

    const actionItems = [
        {
            label: 'View All',
            description:
                'View All domain names',
            component: {NavLink},
            to: '/domain-names',
            visible: true
        },
    ];

    if (allowedActions.includes('AddDomainName')) {
        actionItems.push({
                             label: 'Add Domain Name',
                             description:
                                 'Add new domain name',
                             component: {NavLink},
                             to: '/domain-names/add',
                             visible: true,
                         })
    }

    const widget: WidgetType = {
        header: {
            title: 'Domain Names',
        },
        body: {
            dataPoints: [{
                value: items.length,
                label: 'Domain Names',
                color: 'flexinet',
            }],
            metrics: [],
        },
        footer: {
            actionItems: actionItems,
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
