import {config as API} from './api';
import {config as AuthConfig} from './auth';
import {appSyncConfig} from './appsync.ts'
import {ResourcesConfig} from "aws-amplify";
import {storageConfig} from './storage';

const endpoint = appSyncConfig.endpoint;

// see this - https://docs.amplify.aws/javascript/tools/libraries/configure-categories/
export const config = {
    Auth: AuthConfig,
    API: {
        REST: API,
        GraphQL: {
            endpoint: endpoint,
            region: 'us-west-2',
            defaultAuthMode: 'userPool'
        }
    },
    Storage: storageConfig,
} as ResourcesConfig;