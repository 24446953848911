import {stage} from './stage';

const stripe = {
    prod: {
        publishableKey:
            'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs',
    },
    test: {
        publishableKey:
            'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs',
    },
    dev: {
        publishableKey:
            'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs',
    },
    ide: {
        publishableKey:
            'pk_test_51J9MGPHi5nXRQeoomKKMfIBELXcB97ZeQRZgwZfXQoRwIDpjM5mQk7fv4wKhBq7630bWymsAX7acxtJnjPi4a2gF00a13mABrs',
    },
};

export default {
    publishableKey: stripe[stage].publishableKey,
};
