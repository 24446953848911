import {
    deleteRegistrar,
    fetchRegistrar,
    saveRegistrar,
    updateRegistrar,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Registrar} from '../types';

export const useRegistrar = (registrarId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, registrarId ? registrarId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof registrarId === 'undefined') {
            throw new Error('registrarId is required parameter');
        }
        dispatch(fetchRegistrar(registrarId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Registrar) => {
        dispatch(saveRegistrar(values));
    };

    const updateRecord = (values: Registrar) => {
        if (typeof registrarId === 'undefined') {
            throw new Error('registrarId is required parameter');
        }

        dispatch(updateRegistrar({
                                     id: registrarId,
                                     record: values,
                                 }));
    };

    const deleteRecord = () => {
        if (typeof registrarId === 'undefined') {
            throw new Error('registrarId is required parameter');
        }
        dispatch(deleteRegistrar(registrarId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
