import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {BiAt} from 'react-icons/bi'
import {
    MdCollections,
    MdDns
} from "react-icons/md";

import {
    AiOutlineAim,
    AiOutlineDashboard,
    AiOutlineSetting,
    AiOutlineTransaction
} from 'react-icons/ai'


export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <AiOutlineDashboard size={'2em'}/>,
            rbac: 'GetDashboard',
        }, {
            id: uuidv4(),
            link: '/getting-started',
            label: 'Getting Started',
            icon: <AiOutlineAim size={'2em'}/>,
            rbac: 'ViewGettingStarted',
        }, {
            id: uuidv4(),
            link: '/domain-names',
            label: 'Domain Names',
            icon: <BiAt size={'2em'}/>,
            rbac: 'ListDomainNames',
        }, {
            id: uuidv4(),
            link: '/registrars',
            label: 'Registrars',
            icon: <MdDns size={'2em'}/>,
            rbac: 'ListRegistrars',
        }, {
            id: uuidv4(),
            link: '/top-level-domains',
            label: 'Top Level Domains',
            icon: <MdCollections size={'2em'}/>,
            rbac: 'ListTopLevelDomains',
        }, {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Service Plans',
            icon: <AiOutlineTransaction size={'2em'}/>,
            rbac: 'ListServicePlans',
        }, {
            id: uuidv4(),
            link: '/settings',
            label: 'Settings',
            icon: <AiOutlineSetting size={'2em'}/>,
            rbac: 'ListSettings'
        }] as PlatformNavbarMenuItem[];

};

