import {Field} from 'formik';

import {
    Container,
    Fieldset,
    Text,
    Title
} from '@mantine/core';

import {
    FormikDatePickerInput,
    FormikSelect,
    FormikTextInput
} from '@flexinet/ui-components';
import {RegistrarsAutocomplete} from "../../registrars/inputs/RegistrarsAutocomplete.tsx";


export const FormComponent = () => {

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Domain Name</Text></Title>} px={'xl'} mb={'xl'}>

                <Field
                    name='name'
                    label='Name'
                    description='Please enter the domain name. i.e. example.com'
                    component={FormikTextInput}
                    withAsterisk
                    my={'xl'}
                />

                {/*    <Field*/}
                {/*        name='tldId'*/}
                {/*        label='Top Level Domain'*/}
                {/*        description='Please enter the top level domain'*/}
                {/*        component={TopLevelDomainsAutocomplete}*/}
                {/*        withAsterisk*/}
                {/*    />*/}


                <Field
                    name='registrarId'
                    label='Registrar'
                    description='Please enter the registrar'
                    component={RegistrarsAutocomplete}
                    my={'xl'}
                    withAsterisk
                />

            </Fieldset>

            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Transaction</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='purchaseDate'
                    label='Purchase Date'
                    description='Please enter date of purchase'
                    component={FormikDatePickerInput}
                    my={'xl'}
                />
                <Field
                    name='expirationDate'
                    label='Expiration Date'
                    description='Please enter date of expiry'
                    component={FormikDatePickerInput}
                    my={'xl'}
                />

            </Fieldset>

            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Meta Data</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='category'
                    label='Category'
                    description='Please enter category'
                    data={[{
                        label: 'ecommerce',
                        value: 'ecommerce'
                    }, {
                        label: 'social media',
                        value: 'social media'
                    }, {
                        label: 'portfolio',
                        value: 'portfolio'
                    }]}
                    component={FormikSelect}
                    my={'xl'}
                />
            </Fieldset>

        </Container>
    );
};
