import {I18n} from '@aws-amplify/core';

import {
    FlexiAddButton,
    ReloadButton,
    SimplePageHeader,
} from '@flexinet/ui-components';

import {Box} from '@mantine/core';

import {useNavigate} from 'react-router-dom';

import {useTopLevelDomains} from '../hooks/useTopLevelDomains';
import {DeleteRecordButton} from "../buttons/DeleteRecordButton.tsx";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

const ComponentsReloadButton = () => {

    const {fetchRecords} = useTopLevelDomains();
    return (
        <Box mx='s'>
            <ReloadButton onClick={() => fetchRecords()}/>
        </Box>
    );
};

const AddRecordButton = ({
                             buttonText = 'Add Top Level Domain',
                             size = 'sm',
                             onClick,
                         }: {
    buttonText?: string,
    size?: string,
    onClick?: () => void
}) => {
    const navigate = useNavigate();

    if (typeof onClick === 'undefined') {
        onClick = () => {
            navigate('add');
        };
    }

    return (
        <FlexiAddButton
            onClick={onClick}
            size={size}
        >
            {I18n.get(buttonText)}
        </FlexiAddButton>
    );
};


const useTools = () => {
    const {allowedActions} = useVerifiedPermissions()


    const tools = [<ComponentsReloadButton key='reload'/>];

    if (allowedActions.includes('DeleteTopLevelDomain')) {
        tools.push(<DeleteRecordButton key='delete'/>);
    }

    if (allowedActions.includes('CreateTopLevelDomain')) {
        tools.push(<AddRecordButton key='add'/>);
    }


    return tools;
};

export const IndexPageHeader = () => {
    return (
        <SimplePageHeader
            title={I18n.get('Top Level Domains')}
            tools={useTools()}
        />
    );
};
