import {useTopLevelDomains} from '../hooks/useTopLevelDomains';

import {useTopLevelDomainsSelection} from "../hooks/useTopLevelDomainsSelection.ts";
import {DeleteRecordsConfirmationButton} from "@flexinet/ui-components";

export const DeleteRecordButton = () => {

    const {deleteRecords} = useTopLevelDomains();

    const {
        selection,
        handleClearSelection,
    } = useTopLevelDomainsSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};



