import {Anchor,} from '@mantine/core';
import {Registrar,} from '../types';
import {PlatformPropertyCard} from "@flexinet/ui-components";


export const Profile = ({record}: { record: Registrar }) => {
    console.debug(record)

    const properties = [
        {
            label: 'Name',
            value: record.name as string,
            span: 12,
        }, {
            label: 'Description',
            value: record.description as string,
            span: 12,
        }, {
            label: 'Website',
            value: <Anchor href={record.website} target={"_blank"}>{record.website}</Anchor>,
            span: 12,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : null,
            rbac: 'UpdateRegistrar',
            span: 12,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 12,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 12,
        }];


    return (
        <PlatformPropertyCard properties={properties}/>
    );


};
