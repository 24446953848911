import {AppLayout} from "../layout/AppLayout.tsx";
import {PublicHomePage} from "../features/home/pages/PublicHomePage.tsx";
import {PublicDetailPage} from "../features/domain-names/pages/PublicDetailPage.tsx";
import {AboutPage} from "../features/home/pages/AboutPage.tsx";
import {ProductsPage} from "../features/home/pages/ProductsPage.tsx";
import {PricingPage} from "../features/home/pages/PricingPage.tsx";
import {SignOutPage} from "../features/home/pages/SignOutPage.tsx";
import {RequireAuth} from "../utils/RequireAuth.tsx";
import {IndexPage as DashboardPage} from "../features/dashboard/pages/IndexPage.tsx";
import {IndexPage as GettingStartedPage} from "../features/getting-started/pages/IndexPage.tsx";
import {DomainNamesLayout} from "../features/domain-names/layout.tsx";
import {RegistrarsLayout} from "../features/registrars/layout.tsx";
import {TopLevelDomainsLayout} from "../features/top-level-domains/layout.tsx";
import {SettingsLayout} from "../features/settings/layout.tsx";
import {ServicePlansLayout} from "../features/service-plans/layout.tsx";
import {PageNotFound} from "@flexinet/ui-components";


export const routesConfig = [{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <PublicHomePage/>
    }, {
        path: 'name/:name',
        element: <PublicDetailPage/>
    }, {
        path: 'about',
        element: <AboutPage/>
    }, {
        path: 'products',
        element: <ProductsPage/>
    }, {
        path: 'pricing',
        element: <PricingPage/>
    }, {
        path: 'sign-out',
        element: <SignOutPage/>
    }, {
        path: 'dashboard',
        element: <RequireAuth><DashboardPage/></RequireAuth>
    }, {
        path: 'getting-started',
        element: <RequireAuth><GettingStartedPage/></RequireAuth>
    }, {
        path: 'domain-names/*',
        element: <DomainNamesLayout/>
    }, {
        path: 'registrars/*',
        element: <RequireAuth><RegistrarsLayout/></RequireAuth>
    }, {
        path: 'top-level-domains/*',
        element: <RequireAuth><TopLevelDomainsLayout/></RequireAuth>
    }, {
        path: 'settings/*',
        element: <RequireAuth><SettingsLayout/></RequireAuth>
    }, {
        path: 'service-plans/*',
        element: <ServicePlansLayout/>
    }, {
        path: '*',
        element: <PageNotFound message={'Not Found'}/>
    }]
}]
