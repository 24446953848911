export const createDomainName = `
    mutation createDomainName($input: CreateDomainNameInput!) {
        createDomainName(input:$input) {
            id
            name
            tldId
            topLevelDomain {
                id
                name
            }
            category
            purchaseDate
            expirationDate
            registrarId
            registrar {
                id
                name
            }
            createdByUsername
            created
            updated
    }
}`;

export const updateDomainName = `
    mutation updateDomainName($input: UpdateDomainNameInput!) {
        updateDomainName(input:$input){
            id
            name
            tldId
            topLevelDomain {
                id
                name
            }
            category
            purchaseDate
            expirationDate
            registrarId
            registrar {
                id
                name
            }
            createdByUsername
            created
            updated
        }
}`;

export const deleteDomainName = `
    mutation deleteDomainName($input: DeleteDomainNameInput!) {
        deleteDomainName(input:$input) {
            id
        }
}`;
