import {
    getRegistrar,
    listRegistrars
} from './queries';
import {
    createRegistrar,
    deleteRegistrar,
    updateRegistrar
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';

import {Registrar} from "../types.ts";

import {generateClient} from "aws-amplify/api";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";

export type ListRegistrars = {
    listRegistrars: { items: Registrar[] }
}

export type GetRegistrar = {
    getRegistrar: Registrar
}

export type CreateRegistrar = {
    createRegistrar: Registrar
}

export type UpdateRegistrar = {
    updateRegistrar: Registrar
}

export type DeleteRegistrar = {
    deleteRegistrar: string
}

const client = generateClient();


export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListRegistrars>>({
                                                                                query: (listRegistrars),
                                                                                variables: {filter: filter},
                                                                            });
        return response.data?.listRegistrars.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetRegistrar>>({
                                                                              query: (getRegistrar),
                                                                              variables: {id: id},
                                                                          });
        return response.data?.getRegistrar;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<CreateRegistrar>>({
                                                                                 query: (createRegistrar),
                                                                                 variables: {input: payload},
                                                                             });

        showSuccessNotification();
        return response.data?.createRegistrar;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Registrar) => {

    console.debug('IN UPDATE REGISTRAR', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateRegistrar>>({
                                                                                 query: (updateRegistrar),
                                                                                 variables: {input: record},
                                                                             });

        showSuccessNotification();
        return response.data?.updateRegistrar;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteRegistrar>>({
                                                                                 query: (deleteRegistrar),
                                                                                 variables: {input: {id: id}},
                                                                             });
        showSuccessNotification();
        return response.data?.deleteRegistrar;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
