import {useRef} from 'react';
import {I18n} from '@aws-amplify/core';

import {
    Field,
    FieldProps
} from 'formik';

import {
    Box,
    Container,
    Group
} from '@mantine/core';

import {AiOutlineUpload} from 'react-icons/ai';


import {
    FlexiAddButton,
    FormikTextInput
} from "@flexinet/ui-components";
import {uploadData} from "@aws-amplify/storage";
import {showSuccessNotification} from "../../../components/ServiceNotifications.tsx";


export const FileUploadInput = ({
                                    field: {
                                        name,
                                        value
                                    },
                                    form: {setFieldValue},
                                }: FieldProps) => {

    const onProgress = (
        {
            transferredBytes,
            totalBytes
        }: {
            transferredBytes: number;
            totalBytes: number;
        }
    ) => {
        if (totalBytes) {
            console.debug(
                `Upload progress ${
                    Math.round((transferredBytes / totalBytes) * 100)
                } %`
            );

            showSuccessNotification(
                `Upload progress ${
                    Math.round((transferredBytes / totalBytes) * 100)
                } %`
            );
        }
    }

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {

        console.debug('handleFileUpload', event.currentTarget.files);

        if (event.currentTarget.files) {

            const file = event.currentTarget.files[0];

            console.debug('file name', file.name);

            try {
                // const listResult = await list({
                //                                   path: ({identityId}) => `private/${identityId}/`,
                //                               })
                // console.debug(listResult)

                const result = await uploadData({
                                                    path: ({identityId}) => `private/${identityId}/${file.name}`,
                                                    data: file,
                                                    options: {
                                                        onProgress // Optional progress callback.
                                                    }
                                                }).result;

                console.debug('Succeeded: ', result);
                setFieldValue(name, result.path);


            } catch (error) {
                console.debug('Error : ', error);
            }


        }
    };

    // textInput must be declared here so the ref can refer to it  const textInput = useRef(null);
    const fileInput = useRef<HTMLInputElement>(null);

    const handleUploadInputFieldClick = () => {
        if (fileInput.current) {
            fileInput.current.click();
        }
        return false;
    };

    return (
        <Box>
            <Group grow>
                <Field
                    name={name}
                    value={value}
                    disabled={true}
                    placeholder="Select file from your computer"
                    component={FormikTextInput}
                />

                <Container>
                    <FlexiAddButton
                        leftSection={<AiOutlineUpload/>}
                        onClick={handleUploadInputFieldClick}
                    >
                        {I18n.get('Select File')}
                    </FlexiAddButton>
                </Container>
            </Group>

            <input
                type="file"
                accept=".ods, .csv"
                style={{display: 'none'}}
                ref={fileInput}
                onChange={handleFileUpload}
            />
        </Box>
    );
};