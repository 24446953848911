import {
    getTopLevelDomain,
    listTopLevelDomains
} from './queries.js';
import {
    createTopLevelDomain,
    deleteTopLevelDomain,
    updateTopLevelDomain,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {TopLevelDomain} from "../types.ts";
import {generateClient} from "aws-amplify/api";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";


export type ListTopLevelDomains = {
    listTopLevelDomains: { items: TopLevelDomain[] }
}

export type GetTopLevelDomain = {
    getTopLevelDomain: TopLevelDomain
}

export type CreateTopLevelDomain = {
    createTopLevelDomain: TopLevelDomain
}

export type UpdateTopLevelDomain = {
    updateTopLevelDomain: TopLevelDomain
}

export type DeleteTopLevelDomain = {
    deleteTopLevelDomain: string
}

const client = generateClient();

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListTopLevelDomains>>({
                                                                                     query: (listTopLevelDomains),
                                                                                     variables: {filter: filter},
                                                                                 });
        return response.data?.listTopLevelDomains.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetTopLevelDomain>>({
                                                                                   query: (getTopLevelDomain),
                                                                                   variables: {id: id},
                                                                               });
        return response.data?.getTopLevelDomain;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<CreateTopLevelDomain>>({
                                                                                      query: (createTopLevelDomain),
                                                                                      variables: {input: payload},
                                                                                  });

        showSuccessNotification();
        return response.data?.createTopLevelDomain;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: TopLevelDomain) => {

    console.debug('IN UPDATE TOP LEVEL DOMAIN', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateTopLevelDomain>>({
                                                                                      query: (updateTopLevelDomain),
                                                                                      variables: {input: record},
                                                                                  });

        showSuccessNotification();
        return response.data?.updateTopLevelDomain;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteTopLevelDomain>>({
                                                                                      query: (deleteTopLevelDomain),
                                                                                      variables: {input: {id: id}},
                                                                                  });
        showSuccessNotification();
        return response.data?.deleteTopLevelDomain;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
