import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';

import {IndexPage} from './pages/IndexPage';
import {useVerifiedPermissions} from "../../hooks/useVerifiedPermissions.ts";

export const SettingsLayout = () => {

    const {allowedActions} = useVerifiedPermissions()

    return (
        <Routes>
            <Route
                path='/'
                element={
                    allowedActions.includes('ListSettings') ? (
                        <IndexPage/>
                    ) : (
                        <Navigate to='/'/>
                    )
                }
            >

                <Route
                    path=':tab'
                    element={
                        allowedActions.includes('ListSettings') ? (
                            <IndexPage/>
                        ) : (
                            <Navigate to='/'/>
                        )
                    }
                />

            </Route>

        </Routes>
    );
};
