import {
    deleteServicePlan,
    fetchServicePlan,
    saveServicePlan,
    updateServicePlan,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {ServicePlan} from '../types';

export const useServicePlan = (servicePlanId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, servicePlanId ? servicePlanId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof servicePlanId === 'undefined') {
            throw new Error('servicePlanId is required parameter');
        }
        dispatch(fetchServicePlan(servicePlanId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: ServicePlan) => {
        dispatch(saveServicePlan(values));
    };

    const updateRecord = (values: ServicePlan) => {
        if (typeof servicePlanId === 'undefined') {
            throw new Error('servicePlanId is required parameter');
        }

        dispatch(updateServicePlan({
                                       id: servicePlanId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof servicePlanId === 'undefined') {
            throw new Error('servicePlanId is required parameter');
        }
        dispatch(deleteServicePlan(servicePlanId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
