import {
    Outlet,
    useNavigate
} from 'react-router-dom';


import {
    FlexiSignInButton,
    PlatformAppShell,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
    PlatformPublicAppShell
} from '@flexinet/ui-components';
import {Suspense} from "react";
import {PortalNavbar} from "./menu-items/pages/PortalNavbar.tsx";
import {useUserAttributes} from "../hooks/useUserAttributes.ts";
import {AiOutlineLogout} from "react-icons/ai";
import config from "../config";
import {Box} from "@mantine/core";

import classes from './AppLayout.module.css'
import {signInWithRedirect} from 'aws-amplify/auth';

export const AppLayout = () => {
    const navigate = useNavigate()

    const {
        givenName,
        familyName,
        email
    } = useUserAttributes()


    let userInfo = undefined
    if (givenName && familyName && email) {
        userInfo = {
            name: [givenName, familyName].join(' '),
            email: email
        }
    }

    const additionalMenuItems = [{
        title: 'Sign Out',
        link: '/sign-out',
        onClick: () => navigate('/sign-out'),
        icon: <AiOutlineLogout/>
    }]

    const menuItems = [{
        title: 'Home',
        onClick: () => navigate('/')
    }, {
        title: 'Products',
        onClick: () => navigate('/products')
    }, {
        title: 'Pricing',
        onClick: () => navigate('/pricing')
    }, {
        title: 'About',
        onClick: () => navigate('/about')
    }]

    if (!email) {


        const additionalToolbarItems = [
            <FlexiSignInButton onClick={
                () => {
                    signInWithRedirect()
                }
            }>Sign In</FlexiSignInButton>,
        ]

        const showLoginButton = false
        if (showLoginButton) {
            additionalToolbarItems.push(<FlexiSignInButton
                onClick={() => window.location.assign(config.platform.portal + '/login?returnTo=' +
                                                          config.platform.domains
                )}>Login</FlexiSignInButton>)
        }

        return <PlatformPublicAppShell
            platformHeader={<PlatformHeader
                navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}
                toolbar={<PlatformHeaderToolbar additionalToolbarItems={additionalToolbarItems}/>}/>}>
            <Suspense fallback={<PlatformLoader/>}>
                <Outlet/>
            </Suspense>
        </PlatformPublicAppShell>

    }

    return (
        <PlatformAppShell
            platformNavbar={email ? <PortalNavbar/> : <div/>}
            platformHeader={
                <PlatformHeader
                    toolbar={<PlatformHeaderToolbar
                        user={userInfo ? userInfo : undefined}
                        portalUrl={config.platform.portal}
                        additionalMenuItems={additionalMenuItems}/>}/>
            }
        >

            <Suspense fallback={<PlatformLoader/>}>
                <Box className={classes.main}>
                    <Outlet/>
                </Box>
            </Suspense>

        </PlatformAppShell>
    );


};
