import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {
    Anchor,
    Grid
} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
    SimplePageContentWrapper,
} from '@flexinet/ui-components';
import {Profile} from '../profile/Profile';

import {EditPageNavigation} from '../navigation/EditPageNavigation';
import {useDomainName} from '../hooks/useDomainName';
import {useDomainNamesSelection} from '../hooks/useDomainNamesSelection';
import {useEditPageTools} from '../hooks/useEditPageTools';

export const EditPage = () => {

    const {id: componentId} = useParams();

    if (typeof componentId === 'undefined') {
        throw new Error('componentId is required parameter');
    }

    const {
        record: component,
        fetchRecord,
        updateRecord,
    } = useDomainName(componentId);

    const {handleClearSelection} = useDomainNamesSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [componentId]);

    if (!component) {
        return <PlatformLoader message='Loading application...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={component.name ? component.name : component.id}
            pageNavigation={<EditPageNavigation component={component}/>}
            tools={tools}
        >


            {mode === 'view' ?


                <Grid>
                    <Grid.Col span={{
                        base: 12,
                        lg: 4
                    }}>
                        <Profile record={component}/>
                    </Grid.Col>
                    <Grid.Col span={{
                        base: 12,
                        lg: 8
                    }}>
                        <SimplePageContentWrapper>
                            <Anchor href={`http://${component.name}`}
                                    target={'_blank'}>{`http://${component.name}`}</Anchor>
                        </SimplePageContentWrapper>
                    </Grid.Col>
                </Grid>


                :
                <RecordForm
                    record={component}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
