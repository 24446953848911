import {Field} from 'formik';

import {
    FormikTextarea,
    FormikTextInput
} from '@flexinet/ui-components';
import {
    Container,
    Fieldset,
    Text,
    Title
} from "@mantine/core";


export const FormComponent = () => {

    return (
        <Container>
            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Registrar</Text></Title>} px={'xl'} mb={'xl'}>

                <Field
                    name='name'
                    label='Name'
                    description='Please enter the application name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Fieldset>

            <Fieldset legend={<Title order={3}><Text tt={'uppercase'}>Details</Text></Title>} px={'xl'} mb={'xl'}>
                <Field
                    name='description'
                    label='Description'
                    description='Please enter the registrar descripiton'
                    component={FormikTextarea}
                />

                <Field
                    name='website'
                    label='Website'
                    description='Please enter the registrar website'
                    component={FormikTextInput}
                />

            </Fieldset>
        </Container>
    );
};
