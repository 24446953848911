import moment from 'moment';

export const formatTimestamp = (ts: string | number) => (
    <>
        {moment(ts)
            .format('MMM DD, YYYY HH:mm:ss')}
    </>
);

export const formatDate = (ts: string | number) => (
    <>
        {moment(ts)
            .format('MMM DD, YYYY')}
    </>
);
